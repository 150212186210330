import {CraftId} from '../../../backend/craft/craft-types';
import {httpPost} from '../../../backend/http';

export interface UserProfile {
  userId: CraftId;
  firstName?: string;
  lastName?: string;
  email?: string;
  currentPassword?: string;
  newPassword?: string;
  username?: string;
}

export type UpdateProfileErrors = {
  [field: string]: Array<string>;
};

export function getErrors(errors: Readonly<UpdateProfileErrors>): string {
  const fields = Object.keys(errors).map(field => {
    return errors[field].join(' ');
  });
  return fields.join(';');
}

export interface UpdateProfileResponse {
  ok: boolean;
  errors?: UpdateProfileErrors;
}

export async function updateProfile(profile: Readonly<UserProfile>): Promise<boolean> {
  const url = '/actions/users/save-user';
  const {ok, bodyJson} = await httpPost(url, profile);

  // If calling the action was successful, just return.
  if (ok) {
    return true;
  }
  if (bodyJson === undefined) {
    throw new Error('Empty response');
  }
  // If not, throw with the provided error message (if any)
  const response = bodyJson as UpdateProfileResponse;
  if (!response.ok) {
    let message = 'Unknown error';
    if (response.errors !== undefined) {
      message = getErrors(response.errors);
    }
    throw new Error(message);
  }
  return true;
}
