import {ACCESS_PASS_FIELDS} from '../access-pass/access-pass-query';
import {CraftId} from '../craft/craft-types';
import {fetchEntries} from '../craft/entry/entry-query';
import {OFFER_CONDITIONS_FIELD} from './offer-conditions-query';
import {ResubscribeOffer} from './resubscribe-offer-types';
import * as QueryBuilder from '../craft/query/craft-query-builder';

export const RESUBSCRIBE_OFFER_FIELDS = [
  QueryBuilder.plainText('offerTitle').required(),
  QueryBuilder.lightswitch('offerIsActive').required(),
  QueryBuilder.categories('offerDestination').required().fields(ACCESS_PASS_FIELDS),
  QueryBuilder.categories('offerDeletePasses').fields(ACCESS_PASS_FIELDS),
  QueryBuilder.plainText('offerFeatures'),
  QueryBuilder.number('offerTrialDuration'),
  QueryBuilder.plainText('summary'),
  QueryBuilder.lightswitch('offerHighlight').required(),
  QueryBuilder.plainText('offerSelectionLabel'),
  OFFER_CONDITIONS_FIELD
];

export async function getAllResubscribeOffers(): Promise<ReadonlyArray<ResubscribeOffer>> {
  const result = await fetchEntries<ResubscribeOffer>(
    QueryBuilder.entries().section(['resubscribeOffers']).fields(RESUBSCRIBE_OFFER_FIELDS)
  );
  return result;
}

export async function getResubscribeOfferById(
  id: CraftId
): Promise<Readonly<ResubscribeOffer> | undefined> {
  const result = await fetchEntries<ResubscribeOffer>(
    QueryBuilder.entries().section(['resubscribeOffers']).id([id]).fields(RESUBSCRIBE_OFFER_FIELDS)
  );
  return result[0];
}
